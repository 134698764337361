import React from "react";
import hands2 from "../img/hands2.jpg";

import facebook from "../img/fb.png";
import instagram from "../img/insta.png";
import linkedin from "../img/linkedin.png";

import { Newsletter } from "./Newsletter";

import { Button, TextField } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import volunteers from "../img/volunteers.jpg";
import { useTranslation } from "react-i18next";

export const GetInvolved = () => {
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState();

  const [socialMediaDetails, setSocialMediaDetails] =
    React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/get-involved?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setDetails(attributes);
      });

    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/footer?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setSocialMediaDetails(attributes);
      });
  }, [i18n.language]);

  return (
    <div className="get-involved-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">
          {details?.textOverImage}
        </div>
      </div>

      <div className="page-title-section">
        {details?.textDescription}
      </div>

      <div className="get-involved-content-wrapper">
        <div className="contact-us-wrapper">
          <div className="raw-content-wrapper">
            <ReactMarkdown>
              {details?.contactUsSection}
            </ReactMarkdown>
          </div>
          <div
            className="social-media-wrapper"
            style={{ marginTop: "20px" }}
          >
            <div
              className="social-media-item-wrapper"
              onClick={() => {
                window.open(
                  socialMediaDetails.facebookURL,
                  "_BLANK"
                );
              }}
            >
              <img
                src={facebook}
                className="social-media-item"
              />
            </div>
            <div
              className="social-media-item-wrapper"
              onClick={() => {
                window.open(
                  socialMediaDetails.instagramURL,
                  "_BLANK"
                );
              }}
            >
              <img
                src={instagram}
                className="social-media-item"
              />
            </div>
            <div
              className="social-media-item-wrapper"
              onClick={() => {
                window.open(
                  socialMediaDetails.linkedinURL,
                  "_BLANK"
                );
              }}
            >
              <img
                src={linkedin}
                className="social-media-item"
              />
            </div>
          </div>
        </div>

        <div className="volunteer-wrapper">
          <ReactMarkdown>
            {details?.becomeVolunteerSection}
          </ReactMarkdown>
        </div>
      </div>

      <Newsletter />
    </div>
  );
};

const Redirect20 = ({ title, description }) => {
  return (
    <div className="redirect-20">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  );
};
