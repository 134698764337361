import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useTranslation } from "react-i18next";

export const Donate = () => {
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/donate?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        setDetails(data.attributes);
      });
  }, [i18n.language]);

  return (
    <div className="donate-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">
          {details?.textOverImage}
        </div>
      </div>

      <div className="donate-page-content-wrapper">
        <ReactMarkdown
        // remarkPlugins={[remarkGfm]}
        >
          {details?.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};
